class ZIDXAccountListView {
    searchURL:string="";
    token:string="";
    formElement:HTMLFormElement|null=null;
    constructor() {
        let form=ZIDX.$(".zidxPageActive .zidxSearchListViewForm");
        if(form.length>0) {
            this.formElement = <HTMLFormElement>form[0];
            this.token = this.formElement.id.replace("zidxSearchListViewForm", "");
        }
    }
    render(){
        if(this.formElement==null){
            return;
        }
        let self=this;
        ZIDX.$(this.formElement).on("submit", function(this:HTMLFormElement, e:SubmitEvent){
            e.preventDefault();
            ZIDX.$("input[name='offset']", this).val(0);
            self.loadData(this.id, this.action);
            ZIDX.$(".zidxSearchListViewShowAll", this).css("display", "inline-block");
        });
        ZIDX.$(".zidxSearchListViewShowAll", this.formElement).on("click", function(this:HTMLAnchorElement, e:MouseEvent){
            e.preventDefault();
            this.style.display="none";
            ZIDX.loadPushStateURL(this.href, false);
        });
        ZIDX.$(window).on("popstate", function (e:any) {
            let state=(<PopStateEvent>e.originalEvent).state;
            if(typeof state.zidxToken=="undefined" || state.zidxToken!=self.token) {
                return;
            }
            ZIDX.$("#zidxSearchListViewBody"+state.zidxToken).each(function(this:HTMLElement){
                this.outerHTML=state.data;
            });
            self.setFormData(state.search);
            self.drawPageNav("zidxPaginationContainer"+state.zidxToken, self.searchURL, parseInt(state.search.count) ,parseInt(state.search.limit), parseInt(state.search.offset));
        });

        // store initial page state
        let search=this.getFormData();
        history.replaceState({
            // disableSPABack:true,
            search:search,
            token:this.token,
            data:ZIDX.$("#zidxSearchListViewBody"+this.token)[0].outerHTML
        }, document.title, document.location.href);

        this.drawPageNav("zidxPaginationContainer"+this.token, this.searchURL, parseInt(search.count) ,parseInt(search.limit), parseInt(search.offset));
    }
    loadData(id:string, url:string){
        let self=this;
        let postObj=ZIDX.getFormDataByFormId(id);
        postObj.token=this.token;
        let qs=[];
        for(let i in postObj){
            qs.push(i+"="+escape(postObj[i]));
        }
        let tempURL=url+"?"+qs.join("&");
        ZIDX.$(".zidxPageContainer.zidxPageActive").attr("data-last-url", tempURL);
        let obj = {
            id: "ajaxSearchListView",
            method: "get",
            // postObj: postObj,
            ignoreOldRequests: true,
            callback: function(r:string){
                let rs=JSON.parse(r);
                if(rs.success) {
                    document.getElementById("zidxSearchListViewBody"+postObj.token)!.outerHTML=rs.data;
                    window.history.pushState({
                        // disableSPABack:true, // don't do this on first view
                        token:postObj.token,
                        search:self.getFormData(),
                        data:rs.data
                    }, document.title, tempURL);

                    self.drawPageNav("zidxPaginationContainer"+postObj.token, url, rs.count, parseInt(postObj.limit), parseInt(postObj.offset));
                }else{
                    alert("Search failed, please try again later");
                }
            },
            errorCallback: function(r:string){
                alert("Search failed, please try again later");
            },
            url: tempURL+"&ajaxLoad=1"
        };
        ZIDX.ajaxRequest(obj);

    }

    drawPageNav(containerId:string, url:string, count:number, perpage:number, offset:number){
        // if(ZIDX.options.debug) console.log("draw pagenav: "+count+":"+perpage+":"+offset);
        let options=new ZIDXPaginationOptions();
        options.id=containerId;
        options.count=count;
        options.perpage=perpage;
        options.offset=offset;
        let self=this;
        options.loadFunction=function(options:any){
            ZIDX.$("input[name='offset']", self.formElement).val(options.offset);
            self.loadData("zidxSearchListViewForm"+self.token, url);
        }
        let p=new ZIDXPagination(options);
    }
    getFormData(){
        let search:any={};
        ZIDX.$("input", this.formElement).each(function(this:HTMLElement){
            if(this instanceof HTMLInputElement) {
                if(this.type=="checkbox" || this.type=="radio") {
                    search[this.name]=this.checked;
                }else {
                    search[this.name] = this.value;
                }
            }
        });
        ZIDX.$("textarea", this.formElement).each(function(this:HTMLElement){
            if(this instanceof HTMLTextAreaElement) {
                search[this.name] = this.value;
            }
        });
        ZIDX.$("select", this.formElement).each(function(this:HTMLElement){
            if(this instanceof HTMLSelectElement) {
                search[this.name] = this.selectedIndex;
            }
        });
        return search;
    }
    setFormData(search:any){
        ZIDX.$("select, textarea, input", this.formElement).each(function(this:HTMLElement){
            if(this instanceof HTMLInputElement) {
                for (let field in search) {
                    if (this.name == field) {
                        if (this.type == "checkbox" || this.type == "radio") {
                            this.checked = search[field];
                        } else {
                            this.value = search[field];
                        }
                        break;
                    }
                }
            }else if(this instanceof HTMLTextAreaElement){
                for (let field in search) {
                    if (this.name == field) {
                        this.value = search[field];
                        break;
                    }
                }
            }else if(this instanceof HTMLSelectElement) {
                for (let field in search) {
                    if (this.name == field) {
                        this.selectedIndex = search[field];
                        break;
                    }
                }
            }
        });
    }
}